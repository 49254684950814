import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'rm-vertical-card',
    templateUrl: './vertical-card.component.html',
    styleUrls: ['./vertical-card.component.scss']
})
export class VerticalCardComponent implements OnInit {
    @Input() userName: string;
    constructor() { }
    ngOnInit(): void {
        if (!this.userName) {
            this.userName = '';
        }
    }
}
