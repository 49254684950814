import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingSVGComponent } from './loading/loading.component';
import { VerticalCardComponent } from './vertical-card/vertical-card.component';
import { SubscribeSvgComponent } from './subscribe/subscribe-svg.component';
import { UserDetailsSvgComponent } from './user-details/user-details-svg.component';
// import { EmailSentSvgComponent } from './email-sent/email-sent-svg.component';
import { AbstractCardComponent } from './abstract-card/abstract-card.component';
import { CheckingIDentityComponent } from './checking-identity/checking-identity.component';
import { MoreDocumentsComponent } from './documents/more-documents.component';
import { WorkingOnItComponent } from './workin-on-it/working-onit.component';
import { VerifiedComponent } from './verified/verified.component';
import { FlipCardComponent } from './flipable-card/flip-card.component';
import { InfinityLoaderComponent } from './infinity-loader/infinity-loader.component';

const components = [
    LoadingSVGComponent,
    VerticalCardComponent,
    SubscribeSvgComponent,
    UserDetailsSvgComponent,
    // EmailSentSvgComponent,
    AbstractCardComponent,
    CheckingIDentityComponent,
    MoreDocumentsComponent,
    WorkingOnItComponent,
    VerifiedComponent,
    FlipCardComponent,
    InfinityLoaderComponent
];

@NgModule({
    declarations: components,
    imports: [ CommonModule ],
    exports: components,
    providers: [],
})
export class AnimatedSVGModule {}
