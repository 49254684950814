<svg width="568" height="518" viewBox="0 0 568 518" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="resumeAnimcation()">
    <g id="light-credit-card-abstract">
        <g id="card-background" [ngClass]="{'card-background': applyAnimation}">
            <path id="Vector"
                d="M276.484 203.414C276.052 201.87 275.024 200.562 273.627 199.776C272.23 198.99 270.578 198.792 269.034 199.224L169.414 227.084C168.022 227.478 166.817 228.357 166.016 229.561C165.214 230.766 164.87 232.217 165.044 233.654H190.834L197.994 231.654L272.294 210.874C273.838 210.439 275.147 209.409 275.932 208.01C276.718 206.612 276.916 204.959 276.484 203.414V203.414Z"
                fill="#CCCCCC" />
            <path id="Vector_2"
                d="M70.3292 384.481L0.282701 144.068C-0.236604 142.286 -0.0265598 140.37 0.866594 138.743C1.75975 137.115 3.26285 135.909 5.04527 135.39L468.764 0.281189C470.546 -0.236372 472.461 -0.025616 474.088 0.867218C475.715 1.76005 476.921 3.26201 477.442 5.0434L539.498 218.032L537.578 218.592L475.522 5.60297C475.149 4.33108 474.287 3.25886 473.125 2.62122C471.963 1.98357 470.596 1.83249 469.323 2.2011L5.60472 137.31C4.33156 137.68 3.25792 138.542 2.61995 139.705C1.98198 140.867 1.83195 142.236 2.20287 143.509L72.2494 383.922L70.3292 384.481Z"
                fill="#3F3D56" />
            <path id="Vector_3"
                d="M350.676 202.942C350.463 202.177 350.102 201.461 349.612 200.836C349.122 200.21 348.514 199.687 347.822 199.297C347.13 198.907 346.368 198.657 345.58 198.562C344.791 198.467 343.991 198.528 343.226 198.742L276.836 217.312L269.686 219.312H314.586L321.736 217.312L346.486 210.392C348.029 209.958 349.337 208.931 350.122 207.534C350.908 206.137 351.107 204.485 350.676 202.942V202.942Z"
                fill="#CCCCCC" />
            <path id="Vector_4"
                d="M39.814 153.382C39.1741 151.184 39.2355 148.841 39.9899 146.68C40.7442 144.519 42.1538 142.647 44.0224 141.325C42.2717 141.239 40.5261 141.574 38.9308 142.3C37.3355 143.026 35.9368 144.122 34.8512 145.498C33.7656 146.874 33.0244 148.49 32.6896 150.21C32.3547 151.931 32.4358 153.706 32.9261 155.389C33.4164 157.071 34.3016 158.613 35.5081 159.884C36.7147 161.155 38.2075 162.12 39.8623 162.697C41.5171 163.275 43.286 163.449 45.0215 163.204C46.7571 162.96 48.4091 162.304 49.8399 161.292C47.5536 161.18 45.359 160.358 43.5619 158.94C41.7648 157.523 40.4547 155.579 39.814 153.382V153.382Z"
                fill="#F13030" />
            <path id="Vector_5"
                d="M69.2465 144.812C69.1257 144.398 68.9821 143.99 68.8163 143.592C67.6888 140.855 65.5899 138.632 62.9228 137.349C60.2557 136.066 57.2086 135.814 54.367 136.642C51.5254 137.469 49.0898 139.317 47.5281 141.831C45.9665 144.345 45.389 147.348 45.9066 150.262C45.9793 150.687 46.0762 151.108 46.1966 151.522C46.9269 154.014 48.4449 156.203 50.5234 157.761C52.6019 159.319 55.1291 160.161 57.7264 160.162C58.8604 160.164 59.9888 160.003 61.0765 159.682C64.1294 158.79 66.704 156.723 68.2356 153.936C69.7671 151.148 70.1307 147.867 69.2465 144.812V144.812ZM60.5165 157.762C57.9705 158.501 55.2353 158.2 52.9108 156.925C50.5863 155.65 48.8622 153.506 48.1166 150.962C47.9995 150.548 47.9059 150.127 47.8363 149.702C47.4612 147.301 47.9735 144.846 49.2773 142.795C50.5811 140.745 52.5871 139.239 54.9204 138.56C57.2536 137.881 59.7543 138.075 61.9547 139.106C64.1552 140.137 65.9047 141.935 66.8763 144.162C67.0574 144.553 67.2079 144.958 67.3265 145.372C68.0644 147.918 67.7618 150.653 66.4849 152.976C65.2081 155.299 63.0615 157.02 60.5165 157.762V157.762Z"
                fill="#DBD54D" />
            <path id="Vector_6"
                d="M436.862 219.022C435.455 219.02 434.087 218.563 432.961 217.719C431.836 216.875 431.014 215.689 430.619 214.339L418.87 174.016C418.39 172.361 418.586 170.583 419.415 169.072C420.244 167.561 421.638 166.441 423.292 165.957L463.616 154.208C465.271 153.728 467.049 153.924 468.56 154.753C470.071 155.583 471.191 156.977 471.674 158.631L483.423 198.955C483.903 200.61 483.707 202.387 482.878 203.898C482.049 205.409 480.655 206.529 479.001 207.013L438.677 218.762C438.088 218.934 437.477 219.022 436.862 219.022V219.022Z"
                fill="#395F90" />
            <path id="Vector_7" d="M488.79 47.567L13.5508 186.032L26.1385 229.235L501.378 90.7705L488.79 47.567Z"
                fill="#3F3D56" />
        </g>
        <g id="card-front">
            <rect id="Rectangle 1" x="73" y="220" width="491" height="296" rx="9" fill="white" stroke="black"
                stroke-width="2" />
            <text id="Your-Name" fill="#395F90" xml:space="preserve" style="white-space: pre" font-family="Roboto"
                font-size="18" letter-spacing="0em">
                <tspan x="104" y="471.652">{{name}}</tspan>
            </text>
            <text id="business" fill="#395F90" xml:space="preserve" style="white-space: pre" font-family="Roboto"
                font-size="18" font-weight="bold" letter-spacing="0em">
                <tspan x="462.192" y="252.652">business</tspan>
            </text>
            <g id="logo">
                <circle id="bleue-bg" cx="113" cy="249" r="15" fill="#083876" />
                <path id="the-r"
                    d="M110.706 239.979C107.526 240.214 106.192 240.71 103.684 241.735C103.749 242.634 103.897 243.051 104.327 243.664C106.188 243.038 107.292 242.733 109.489 242.36C111.329 242.076 112.339 242.095 114.095 242.499C117.552 243.451 118.081 246.4 116.094 249.226C114.303 251.384 112.65 252.113 109.298 252.806L109.732 243.212C108.888 243.026 108.397 243 107.49 243.09C106.814 249.118 106.693 252.487 106.986 258.473C107.819 258.533 108.29 258.524 109.141 258.403L109.228 255.031C111.453 254.729 112.701 254.34 114.929 253.224L115.022 253.303C118.029 255.892 119.744 257.368 121.586 258.351C122.187 257.859 122.456 257.531 122.751 256.804C120.701 254.957 119.387 253.878 116.98 251.937C118.83 250.46 119.564 249.435 119.779 247.14C119.95 244.284 119.289 243.013 117.154 241.404C114.929 240.01 113.501 239.892 110.706 239.979Z"
                    fill="white" stroke="white" />
            </g>
            <path id="Vector_8" d="M564.81 277.09H71.8101V279.09H564.81V277.09Z" fill="#3F3D56" />
            <g id="cc-number">
                <path id="Vector_9"
                    d="M172.81 386.09C170.424 386.087 168.136 385.138 166.449 383.451C164.762 381.764 163.813 379.476 163.81 377.09V350.09C163.81 347.703 164.758 345.414 166.446 343.726C168.134 342.038 170.423 341.09 172.81 341.09C175.197 341.09 177.486 342.038 179.174 343.726C180.862 345.414 181.81 347.703 181.81 350.09V377.09C181.807 379.476 180.858 381.764 179.171 383.451C177.484 385.138 175.196 386.087 172.81 386.09V386.09Z"
                    fill="#395F90" />
                <path id="Vector_10"
                    d="M196.81 386.09C194.424 386.087 192.136 385.138 190.449 383.451C188.762 381.764 187.813 379.476 187.81 377.09V350.09C187.81 347.703 188.758 345.414 190.446 343.726C192.134 342.038 194.423 341.09 196.81 341.09C199.197 341.09 201.486 342.038 203.174 343.726C204.862 345.414 205.81 347.703 205.81 350.09V377.09C205.807 379.476 204.858 381.764 203.171 383.451C201.484 385.138 199.196 386.087 196.81 386.09V386.09Z"
                    fill="#395F90" />
                <path id="Vector_11"
                    d="M220.81 386.09C218.424 386.087 216.136 385.138 214.449 383.451C212.762 381.764 211.813 379.476 211.81 377.09V350.09C211.81 347.703 212.758 345.414 214.446 343.726C216.134 342.038 218.423 341.09 220.81 341.09C223.197 341.09 225.486 342.038 227.174 343.726C228.862 345.414 229.81 347.703 229.81 350.09V377.09C229.807 379.476 228.858 381.764 227.171 383.451C225.484 385.138 223.196 386.087 220.81 386.09V386.09Z"
                    fill="#395F90" />
                <path id="Vector_12"
                    d="M257.81 386.09C255.424 386.087 253.136 385.138 251.449 383.451C249.762 381.764 248.813 379.476 248.81 377.09V350.09C248.81 347.703 249.758 345.414 251.446 343.726C253.134 342.038 255.423 341.09 257.81 341.09C260.197 341.09 262.486 342.038 264.174 343.726C265.862 345.414 266.81 347.703 266.81 350.09V377.09C266.807 379.476 265.858 381.764 264.171 383.451C262.484 385.138 260.196 386.087 257.81 386.09V386.09Z"
                    fill="#395F90" />
                <path id="Vector_13"
                    d="M281.81 386.09C279.424 386.087 277.136 385.138 275.449 383.451C273.762 381.764 272.813 379.476 272.81 377.09V350.09C272.81 347.703 273.758 345.414 275.446 343.726C277.134 342.038 279.423 341.09 281.81 341.09C284.197 341.09 286.486 342.038 288.174 343.726C289.862 345.414 290.81 347.703 290.81 350.09V377.09C290.807 379.476 289.858 381.764 288.171 383.451C286.484 385.138 284.196 386.087 281.81 386.09V386.09Z"
                    fill="#395F90" />
                <path id="Vector_14"
                    d="M305.81 386.09C303.424 386.087 301.136 385.138 299.449 383.451C297.762 381.764 296.813 379.476 296.81 377.09V350.09C296.81 347.703 297.758 345.414 299.446 343.726C301.134 342.038 303.423 341.09 305.81 341.09C308.197 341.09 310.486 342.038 312.174 343.726C313.862 345.414 314.81 347.703 314.81 350.09V377.09C314.807 379.476 313.858 381.764 312.171 383.451C310.484 385.138 308.196 386.087 305.81 386.09V386.09Z"
                    fill="#395F90" />
                <path id="Vector_15"
                    d="M342.81 386.09C340.424 386.087 338.136 385.138 336.449 383.451C334.762 381.764 333.813 379.476 333.81 377.09V350.09C333.81 347.703 334.758 345.414 336.446 343.726C338.134 342.038 340.423 341.09 342.81 341.09C345.197 341.09 347.486 342.038 349.174 343.726C350.862 345.414 351.81 347.703 351.81 350.09V377.09C351.807 379.476 350.858 381.764 349.171 383.451C347.484 385.138 345.196 386.087 342.81 386.09V386.09Z"
                    fill="#395F90" />
                <path id="Vector_16"
                    d="M366.81 386.09C364.424 386.087 362.136 385.138 360.449 383.451C358.762 381.764 357.813 379.476 357.81 377.09V350.09C357.81 347.703 358.758 345.414 360.446 343.726C362.134 342.038 364.423 341.09 366.81 341.09C369.197 341.09 371.486 342.038 373.174 343.726C374.862 345.414 375.81 347.703 375.81 350.09V377.09C375.807 379.476 374.858 381.764 373.171 383.451C371.484 385.138 369.196 386.087 366.81 386.09V386.09Z"
                    fill="#395F90" />
                <path id="Vector_17"
                    d="M390.81 386.09C388.424 386.087 386.136 385.138 384.449 383.451C382.762 381.764 381.813 379.476 381.81 377.09V350.09C381.81 347.703 382.758 345.414 384.446 343.726C386.134 342.038 388.423 341.09 390.81 341.09C393.197 341.09 395.486 342.038 397.174 343.726C398.862 345.414 399.81 347.703 399.81 350.09V377.09C399.807 379.476 398.858 381.764 397.171 383.451C395.484 385.138 393.196 386.087 390.81 386.09V386.09Z"
                    fill="#395F90" />
                <path id="Vector_18"
                    d="M427.81 386.09C425.424 386.087 423.136 385.138 421.449 383.451C419.762 381.764 418.813 379.476 418.81 377.09V350.09C418.81 347.703 419.758 345.414 421.446 343.726C423.134 342.038 425.423 341.09 427.81 341.09C430.197 341.09 432.486 342.038 434.174 343.726C435.862 345.414 436.81 347.703 436.81 350.09V377.09C436.807 379.476 435.858 381.764 434.171 383.451C432.484 385.138 430.196 386.087 427.81 386.09V386.09Z"
                    fill="#395F90" />
                <path id="Vector_19"
                    d="M451.81 386.09C449.424 386.087 447.136 385.138 445.449 383.451C443.762 381.764 442.813 379.476 442.81 377.09V350.09C442.81 347.703 443.758 345.414 445.446 343.726C447.134 342.038 449.423 341.09 451.81 341.09C454.197 341.09 456.486 342.038 458.174 343.726C459.862 345.414 460.81 347.703 460.81 350.09V377.09C460.807 379.476 459.858 381.764 458.171 383.451C456.484 385.138 454.196 386.087 451.81 386.09V386.09Z"
                    fill="#395F90" />
                <path id="Vector_20"
                    d="M475.81 386.09C473.424 386.087 471.136 385.138 469.449 383.451C467.762 381.764 466.813 379.476 466.81 377.09V350.09C466.81 347.703 467.758 345.414 469.446 343.726C471.134 342.038 473.423 341.09 475.81 341.09C478.197 341.09 480.486 342.038 482.174 343.726C483.862 345.414 484.81 347.703 484.81 350.09V377.09C484.807 379.476 483.858 381.764 482.171 383.451C480.484 385.138 478.196 386.087 475.81 386.09V386.09Z"
                    fill="#395F90" />
            </g>
        </g>
    </g>
</svg>