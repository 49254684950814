<svg width="636" height="640" viewBox="0 0 636 640" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="undraw_Profile_data_re_v81r 1" clip-path="url(#clip0)">
        <g id="big-leaf">
            <path id="Vector"
                d="M614.14 279.451C613.055 285.342 611.755 291.173 610.238 296.944C610.074 297.614 609.89 298.274 609.706 298.944H588.611C586.814 298.946 585.092 299.685 583.821 300.997C582.551 302.31 581.836 304.089 581.834 305.946V368.076C581.195 369.306 580.547 370.526 579.898 371.746C576.548 378.047 573.063 384.278 569.5 390.429C511.408 490.401 430.384 574.06 333.583 634.019L339.315 620.657C337.214 620.507 335.104 620.357 333.003 620.207C287.26 616.976 241.362 613.396 196.694 602.774C149.963 591.662 104.25 572.389 67.6265 540.434C30.9932 508.469 4.13824 462.821 0.410947 413.433C-0.831906 396.46 0.82969 379.393 5.31953 363.015C13.9062 331.49 32.6684 303.125 59.7366 286.342C102.256 259.988 157.718 265.359 202.541 287.283C247.355 309.196 283.833 345.472 319.45 381.118C283.489 317.41 233.421 263.418 173.353 223.572C153.449 210.41 132.325 198.688 115.402 181.635C98.4895 164.592 86.0105 140.699 89.0023 116.485C92.4001 89.0202 114.173 68.1768 135.984 52.1042C144.697 45.6831 153.691 39.5921 162.965 33.9912C189.133 18.1486 217.44 6.19667 247.335 1.76595C287.831 -4.24503 331.57 4.87646 362.617 32.4009C397.595 63.416 411.942 113.434 410.577 161.282C432.127 128.917 465.362 104.143 502.895 103.092C541.861 102.012 578.901 127.446 598.418 162.312C617.925 197.168 621.429 239.895 614.14 279.451Z"
                fill="#DFECDF" />
        </g>
        <g id="branches">
            <path id="Vector_2"
                d="M367.604 413.868C390.427 411.792 411.509 409.366 433.625 402.901C455.062 396.634 475.69 386.143 490.951 369.013C507.365 350.588 516.225 326.661 522.477 302.841C525.962 289.561 528.826 276.095 531.528 262.624C534.46 248.009 537.065 233.329 539.344 218.584C543.879 189.234 547.117 159.711 549.057 130.016C549.291 126.411 549.506 122.805 549.701 119.198C549.823 116.973 546.472 116.98 546.352 119.198C544.758 148.47 541.891 177.627 537.75 206.67C533.664 235.205 528.436 263.616 521.803 291.639C516.184 315.379 508.909 339.49 494.497 359.252C480.983 377.785 461.464 389.895 440.388 397.136C418.942 404.504 396.329 407.446 373.918 409.633C371.169 409.902 370.355 410.157 367.604 410.408C365.474 410.601 365.454 414.064 367.604 413.868L367.604 413.868Z"
                fill="white" />
            <path id="Vector_3"
                d="M7.25247 365.568C36.0474 367.218 64.5494 372.407 92.1559 381.025C119.774 389.625 146.314 401.56 171.227 416.582C195.299 431.109 217.646 448.492 237.813 468.377C257.386 487.686 274.83 509.174 289.827 532.449C304.891 555.782 317.376 580.787 327.046 606.994C328.226 610.202 329.362 613.426 330.454 616.666C331.16 618.756 334.397 617.857 333.684 615.746C324.637 588.985 312.71 563.358 298.127 539.347C283.547 515.333 266.437 493.063 247.108 472.944C227.469 452.514 205.613 434.494 181.973 419.243C132.338 387.301 76.0759 367.977 17.8081 362.859C14.2933 362.554 10.7748 362.304 7.25251 362.107C5.09848 361.986 5.10509 365.447 7.25251 365.568L7.25247 365.568Z"
                fill="white" />
            <path id="Vector_4"
                d="M163.87 38.0478C182.281 44.0971 199.732 52.9073 215.665 64.1963C231.945 75.8104 246.793 89.4333 259.88 104.763C273.671 120.889 285.931 138.346 296.493 156.893C307.696 176.475 317.429 196.917 325.604 218.038C334.023 239.722 341.104 261.935 346.804 284.544C352.598 307.421 357.124 330.621 360.36 354.03C363.579 377.192 365.527 400.523 366.195 423.911C366.868 446.276 366.228 468.662 364.279 490.947C362.496 511.737 359.24 532.364 354.537 552.663C350.36 570.996 344.474 588.868 336.961 606.028C335.221 609.923 333.374 613.763 331.421 617.551C330.408 619.515 333.299 621.266 334.314 619.297C342.616 602.906 349.246 585.668 354.096 567.859C359.537 548.035 363.481 527.805 365.893 507.35C368.566 485.158 369.868 462.813 369.79 440.45C369.742 416.956 368.389 393.484 365.736 370.15C363.082 346.526 359.142 323.074 353.935 299.907C348.74 276.702 342.15 253.855 334.204 231.497C326.545 209.853 317.375 188.812 306.766 168.541C296.662 149.197 284.887 130.836 271.584 113.681C258.951 97.3474 244.528 82.5811 228.604 69.6778C213.249 57.2729 196.281 47.1614 178.199 39.6403C173.781 37.8234 169.301 36.1802 164.76 34.7108C162.699 34.0409 161.817 37.3807 163.87 38.0478H163.87Z"
                fill="white" />
            <path id="Vector_5"
                d="M362.559 640C494.889 640 602.164 635.522 602.164 629.998C602.164 624.475 494.889 619.997 362.559 619.997C230.228 619.997 122.953 624.475 122.953 629.998C122.953 635.522 230.228 640 362.559 640Z"
                fill="#E6E6E6" />
        </g>
        <g id="Group 1">
            <path id="Vector_6"
                d="M264.457 316.737C264.338 317.276 264.261 317.825 264.225 318.377L222.638 343.163L212.53 337.151L201.754 351.726L223.378 367.649L270.823 328.98C272.758 329.72 274.865 329.833 276.865 329.306C278.864 328.778 280.66 327.634 282.014 326.026C283.369 324.418 284.218 322.422 284.447 320.304C284.677 318.186 284.277 316.045 283.301 314.167C282.324 312.289 280.818 310.763 278.981 309.792C277.145 308.82 275.065 308.449 273.02 308.728C270.974 309.007 269.059 309.922 267.53 311.353C266 312.784 264.929 314.661 264.457 316.737V316.737Z"
                fill="#FFB8B8" />
            <path id="Vector_7"
                d="M169.745 331.943L202.49 356.055L217.136 337.084L185.304 310.697C183.935 309.562 182.356 308.728 180.663 308.245C178.97 307.763 177.2 307.642 175.461 307.891C173.722 308.14 172.05 308.753 170.549 309.693C169.047 310.633 167.747 311.88 166.727 313.357V313.357C164.742 316.235 163.944 319.81 164.51 323.296C165.076 326.782 166.959 329.892 169.745 331.943Z"
                fill="#0F4B9A" />
            <path id="Vector_8" d="M102.151 607.056L113.632 610.164L130.7 565.892L113.755 561.305L102.151 607.056Z"
                fill="#FFB8B8" />
            <path id="Vector_9"
                d="M132.981 627.172L96.8974 617.404L100.55 603.001L122.693 608.995C126.39 609.996 129.551 612.473 131.481 615.882C133.41 619.291 133.95 623.352 132.981 627.172V627.172V627.172Z"
                fill="#2F2E41" />
            <path id="Vector_10" d="M204.211 615.501L215.895 613.345L213.405 565.76L196.161 568.943L204.211 615.501Z"
                fill="#FFB8B8" />
            <path id="Vector_11"
                d="M240.366 620.395L203.646 627.172L201.111 612.515L223.644 608.356C225.507 608.013 227.418 608.051 229.267 608.47C231.115 608.889 232.866 609.68 234.419 610.798C235.971 611.916 237.296 613.339 238.316 614.986C239.337 616.632 240.033 618.471 240.366 620.395V620.395V620.395Z"
                fill="#2F2E41" />
            <path id="Vector_12"
                d="M197.494 396.96L219.738 602.788L201.367 609.995L167.483 471.973L120.309 598.171L100.2 599.325C100.2 599.325 127.791 402.961 148.239 398.499L197.494 396.96Z"
                fill="#2F2E41" />
            <path id="Vector_13"
                d="M178.811 291.819C191.943 291.819 202.588 280.821 202.588 267.254C202.588 253.687 191.943 242.689 178.811 242.689C165.679 242.689 155.033 253.687 155.033 267.254C155.033 280.821 165.679 291.819 178.811 291.819Z"
                fill="#FFB8B8" />
            <path id="Vector_14"
                d="M189.016 302.125L163.321 298.662C163.321 298.662 146.563 319.438 147.68 355.217C148.797 390.996 144.887 401.961 144.887 401.961L198.463 402.961C190.718 391.96 223.112 328.772 189.016 302.125Z"
                fill="#3959F9" />
            <path id="Vector_15"
                d="M202.819 407.962C202.283 407.966 201.749 408.014 201.22 408.105L168.348 371.953L171.7 360.411L155.501 352.909L145.446 378.3L192.74 417.166C192.485 419.282 192.86 421.427 193.815 423.317C194.77 425.206 196.259 426.751 198.084 427.745C199.909 428.74 201.984 429.136 204.032 428.882C206.081 428.629 208.006 427.737 209.552 426.325C211.098 424.913 212.192 423.049 212.687 420.98C213.182 418.91 213.056 416.734 212.325 414.741C211.594 412.747 210.293 411.031 208.595 409.82C206.897 408.609 204.882 407.961 202.819 407.962V407.962Z"
                fill="#FFB8B8" />
            <path id="Vector_16"
                d="M166.816 316.19L151.59 354.64L172.817 365.027L190.396 326.976C191.152 325.339 191.577 323.56 191.644 321.747C191.711 319.934 191.42 318.127 190.787 316.435C190.155 314.742 189.194 313.202 187.965 311.906C186.736 310.611 185.264 309.589 183.638 308.902V308.902C182.071 308.239 180.392 307.902 178.698 307.91C177.004 307.918 175.328 308.27 173.766 308.947C172.204 309.623 170.786 310.611 169.594 311.854C168.401 313.097 167.457 314.571 166.816 316.19V316.19Z"
                fill="#0F4B9A" />
            <path id="Vector_17"
                d="M172.591 289.587C176.317 287.367 176.714 282.01 176.208 277.597C175.702 273.185 174.856 268.265 177.366 264.657C180.363 260.351 186.383 260.265 191.515 260.125C196.647 259.984 202.896 258.432 204.226 253.309C205.104 249.925 203.224 246.292 200.527 244.168C197.829 242.043 194.455 241.104 191.175 240.211C183.837 238.214 176.261 236.2 168.747 237.298C161.306 238.385 154.577 242.455 150.018 248.628C145.46 254.801 143.437 262.581 144.387 270.283C145.603 280.136 147.367 284.438 146.209 288.782C144.053 296.874 134.756 302.072 134.571 310.462C134.426 317.025 140.037 322.088 145.512 325.421C149.854 328.064 154.541 330.307 159.545 330.926C164.55 331.545 169.938 330.36 173.666 326.856C178.474 322.336 179.664 314.785 178.285 308.222C176.906 301.659 173.369 295.83 169.871 290.17"
                fill="#2F2E41" />
            <g id="Vector_18" filter="url(#filter0_d)">
                <path
                    d="M622.492 296.944H278.815C276.505 296.948 274.291 297.897 272.658 299.584C271.025 301.272 270.106 303.559 270.102 305.945V533.983C270.106 536.369 271.025 538.656 272.658 540.344C274.291 542.031 276.505 542.981 278.815 542.984H622.492C624.801 542.981 627.015 542.031 628.649 540.344C630.282 538.656 631.201 536.369 631.204 533.983V305.945C631.201 303.559 630.282 301.272 628.649 299.584C627.015 297.897 624.801 296.948 622.492 296.944ZM629.268 533.983C629.266 535.839 628.552 537.618 627.281 538.931C626.011 540.243 624.288 540.982 622.492 540.984H278.815C277.018 540.982 275.296 540.243 274.025 538.931C272.755 537.618 272.04 535.839 272.038 533.983V305.945C272.04 304.089 272.755 302.31 274.025 300.997C275.296 299.685 277.018 298.946 278.815 298.944H622.492C624.288 298.946 626.011 299.685 627.281 300.997C628.552 302.31 629.266 304.089 629.268 305.945V533.983Z"
                    fill="#EBEAFB" />
                <path
                    d="M629.268 533.983C629.266 535.839 628.552 537.618 627.281 538.931C626.011 540.243 624.288 540.982 622.492 540.984H278.815C277.018 540.982 275.296 540.243 274.025 538.931C272.755 537.618 272.04 535.839 272.038 533.983V305.945C272.04 304.089 272.755 302.31 274.025 300.997C275.296 299.685 277.018 298.946 278.815 298.944H622.492C624.288 298.946 626.011 299.685 627.281 300.997C628.552 302.31 629.266 304.089 629.268 305.945V533.983Z"
                    fill="#EBEAFB" />
                <path
                    d="M629.768 533.983V533.983V305.945V305.945C629.766 303.962 629.003 302.057 627.641 300.649C626.278 299.242 624.427 298.447 622.492 298.444H622.492L278.815 298.444L278.815 298.444C276.88 298.447 275.029 299.242 273.666 300.649C272.304 302.057 271.541 303.962 271.538 305.945V305.945L271.538 533.983L271.538 533.983C271.541 535.967 272.304 537.871 273.666 539.279C275.029 540.687 276.88 541.482 278.815 541.484H278.815H622.492H622.492C624.427 541.482 626.278 540.687 627.641 539.279C629.003 537.871 629.766 535.967 629.768 533.983ZM628.768 533.982C628.766 535.711 628.101 537.365 626.922 538.583C625.744 539.8 624.15 540.482 622.491 540.484H278.816C277.157 540.482 275.563 539.8 274.385 538.583C273.206 537.366 272.54 535.711 272.538 533.982C272.538 533.982 272.538 533.982 272.538 533.982V305.946C272.538 305.946 272.538 305.946 272.538 305.946C272.54 304.217 273.206 302.563 274.385 301.345C275.563 300.128 277.157 299.446 278.815 299.444C278.816 299.444 278.816 299.444 278.816 299.444H622.491C624.15 299.446 625.744 300.128 626.922 301.345C628.101 302.563 628.766 304.217 628.768 305.946V533.982ZM273.017 299.932C274.558 298.34 276.644 297.448 278.815 297.444H622.491C624.663 297.448 626.749 298.34 628.289 299.932C629.831 301.525 630.701 303.687 630.704 305.946V533.982C630.701 536.241 629.831 538.403 628.289 539.996C626.748 541.588 624.663 542.481 622.491 542.484H278.816C276.644 542.481 274.558 541.588 273.017 539.996C271.476 538.403 270.606 536.241 270.602 533.982V305.946C270.606 303.687 271.476 301.525 273.017 299.932Z"
                    stroke="black" />
            </g>
            <path id="Vector_19"
                d="M352.173 410.458H297.96C295.778 410.455 293.686 409.559 292.144 407.965C290.601 406.371 289.733 404.211 289.731 401.957V345.948C289.733 343.694 290.601 341.533 292.144 339.939C293.686 338.345 295.778 337.449 297.96 337.446H352.173C354.355 337.449 356.446 338.345 357.989 339.939C359.532 341.533 360.4 343.694 360.402 345.948V401.957C360.4 404.211 359.532 406.371 357.989 407.965C356.446 409.559 354.355 410.455 352.173 410.458V410.458Z"
                fill="#3959F9" />
            <path id="Vector_20"
                d="M604.593 469.977H296.736C295.067 469.977 293.466 469.292 292.286 468.073C291.106 466.854 290.443 465.2 290.443 463.476C290.443 461.752 291.106 460.098 292.286 458.879C293.466 457.66 295.067 456.975 296.736 456.975H604.593C606.261 456.975 607.862 457.66 609.042 458.879C610.222 460.098 610.885 461.752 610.885 463.476C610.885 465.2 610.222 466.854 609.042 468.073C607.862 469.292 606.261 469.977 604.593 469.977V469.977Z"
                fill="#CCCCCC" />
            <path id="Vector_21"
                d="M604.799 438.472H296.942C295.274 438.472 293.673 437.787 292.493 436.568C291.313 435.349 290.65 433.695 290.65 431.971C290.65 430.247 291.313 428.593 292.493 427.374C293.673 426.155 295.274 425.47 296.942 425.47H604.799C606.468 425.47 608.069 426.155 609.249 427.374C610.429 428.593 611.092 430.247 611.092 431.971C611.092 433.695 610.429 435.349 609.249 436.568C608.069 437.787 606.468 438.472 604.799 438.472V438.472Z"
                fill="#CCCCCC" />
            <path id="Vector_22"
                d="M452.807 395.455H407.306C405.637 395.455 404.037 394.77 402.857 393.551C401.676 392.332 401.013 390.678 401.013 388.954C401.013 387.23 401.676 385.576 402.857 384.357C404.037 383.138 405.637 382.453 407.306 382.453H452.807C454.476 382.453 456.076 383.138 457.257 384.357C458.437 385.576 459.1 387.23 459.1 388.954C459.1 390.678 458.437 392.332 457.257 393.551C456.076 394.77 454.476 395.455 452.807 395.455V395.455Z"
                fill="#CCCCCC" />
            <path id="Vector_23"
                d="M604.799 363.46H407.306C405.637 363.46 404.037 362.775 402.857 361.556C401.676 360.336 401.013 358.683 401.013 356.959C401.013 355.235 401.676 353.581 402.857 352.362C404.037 351.143 405.637 350.458 407.306 350.458H604.799C606.468 350.458 608.069 351.143 609.249 352.362C610.429 353.581 611.092 355.235 611.092 356.959C611.092 358.683 610.429 360.336 609.249 361.556C608.069 362.775 606.468 363.46 604.799 363.46V363.46Z"
                fill="#CCCCCC" />
            <path id="Vector_24"
                d="M445.062 501.982H296.736C295.067 501.982 293.466 501.297 292.286 500.078C291.106 498.859 290.443 497.205 290.443 495.481C290.443 493.757 291.106 492.104 292.286 490.884C293.466 489.665 295.067 488.98 296.736 488.98H445.062C446.731 488.98 448.332 489.665 449.512 490.884C450.692 492.104 451.355 493.757 451.355 495.481C451.355 497.205 450.692 498.859 449.512 500.078C448.332 501.297 446.731 501.982 445.062 501.982V501.982Z"
                fill="#CCCCCC" />
            <path id="Vector_25"
                d="M181.204 265.552C184.083 266.276 186.426 269.307 187.832 272.818C189.13 276.506 190.008 280.337 190.45 284.234C191.556 291.492 192.617 299.349 190.331 306.071C187.652 313.95 180.512 318.076 174.32 316.137C168.127 314.198 163.202 306.919 161.519 298.54C159.987 290.074 160.974 281.327 164.35 273.452C166.109 269.314 168.534 265.497 171.756 263.63C173.337 262.643 175.221 262.31 177.029 262.699C178.838 263.089 180.436 264.171 181.503 265.728"
                fill="#2F2E41" />
        </g>
    </g>
    <defs>
        <filter id="filter0_d" x="266.102" y="296.944" width="369.102" height="254.04" filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <clipPath id="clip0">
            <rect width="636" height="640" fill="white" />
        </clipPath>
    </defs>
</svg>
