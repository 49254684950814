import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-infitity-loader',
  templateUrl: './infitity-loader.component.html',
  styleUrls: ['./infitity-loader.component.scss']
})
export class InfinityLoaderComponent implements OnInit {
  constructor() { }

  ngOnInit(): void { }
}
