import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'rm-abstract-card-old',
    templateUrl: './abstract-card.component.html',
    styleUrls: ['./abstract-card.component.scss']
})
export class AbstractCardComponent implements AfterViewInit {
    @Input() name: string;
    applyAnimation = true;
    constructor() { }

    ngAfterViewInit(): void {
        setTimeout(() => this.applyAnimation = false, 5000);
    }

    resumeAnimcation() {
        this.applyAnimation = !this.applyAnimation;
    }
}
