import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'rm-subscribe-svg',
    templateUrl: './subscribe-svg.component.html',
    styleUrls: ['./subscribe-svg.component.scss']
})
export class SubscribeSvgComponent implements OnInit {
    constructor() { }
    ngOnInit(): void { }
}
