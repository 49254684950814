<svg viewBox="0 0 507 597" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M104.26 383H17C12.4929 382.995 8.17181 381.202 4.98479 378.015C1.79777 374.828 0.00507387 370.507 0 366V17C0.00507387 12.4929 1.79777 8.17181 4.98479 4.98479C8.17181 1.79778 12.4929 0.00507387 17 0H253.681L297 38.6782V109H295V39.5737L252.918 2H17C13.0231 2.0045 9.21043 3.5863 6.39836 6.39837C3.58629 9.21044 2.0045 13.0231 2 17V366C2.0045 369.977 3.58629 373.79 6.39836 376.602C9.21043 379.414 13.0231 380.996 17 381H104.26V383Z" fill="#CCCCCC"/>
    <path d="M296 40H269C264.493 39.9949 260.172 38.2022 256.985 35.0152C253.798 31.8282 252.005 27.5071 252 23V1C252 0.734784 252.105 0.480429 252.293 0.292892C252.48 0.105356 252.735 0 253 0C253.265 0 253.52 0.105356 253.707 0.292892C253.895 0.480429 254 0.734784 254 1V23C254.004 26.9769 255.586 30.7896 258.398 33.6016C261.21 36.4137 265.023 37.9955 269 38H296C296.265 38 296.52 38.1054 296.707 38.2929C296.895 38.4804 297 38.7348 297 39C297 39.2652 296.895 39.5196 296.707 39.7071C296.52 39.8947 296.265 40 296 40Z" fill="#CCCCCC"/>
    <path d="M262.54 90.5H34.54C32.4183 90.5 30.3835 91.3428 28.8832 92.8431C27.3829 94.3434 26.54 96.3783 26.54 98.5C26.54 100.622 27.3829 102.657 28.8832 104.157C30.3835 105.657 32.4183 106.5 34.54 106.5H262.54C264.662 106.5 266.697 105.657 268.197 104.157C269.697 102.657 270.54 100.622 270.54 98.5C270.54 96.3783 269.697 94.3434 268.197 92.8431C266.697 91.3428 264.662 90.5 262.54 90.5Z" fill="#E6E6E6"/>
    <path d="M147.54 56.5H34.54C32.4183 56.5 30.3835 57.3428 28.8832 58.8431C27.3829 60.3434 26.54 62.3783 26.54 64.5C26.54 66.6217 27.3829 68.6566 28.8832 70.1568C30.3835 71.6571 32.4183 72.5 34.54 72.5H147.54C149.662 72.5 151.697 71.6571 153.197 70.1568C154.697 68.6566 155.54 66.6217 155.54 64.5C155.54 62.3783 154.697 60.3434 153.197 58.8431C151.697 57.3428 149.662 56.5 147.54 56.5Z" fill="#E6E6E6"/>
    <path d="M34.54 157.5C32.4183 157.5 30.3835 158.343 28.8832 159.843C27.3829 161.343 26.54 163.378 26.54 165.5C26.54 167.622 27.3829 169.657 28.8832 171.157C30.3835 172.657 32.4183 173.5 34.54 173.5H106V157.5H34.54Z" fill="#E6E6E6"/>
    <path d="M34.54 123.5C33.4895 123.5 32.4492 123.707 31.4786 124.109C30.508 124.511 29.626 125.1 28.8832 125.843C28.1403 126.586 27.551 127.468 27.149 128.439C26.747 129.409 26.54 130.449 26.54 131.5C26.54 132.551 26.747 133.591 27.149 134.561C27.551 135.532 28.1403 136.414 28.8832 137.157C29.626 137.9 30.508 138.489 31.4786 138.891C32.4492 139.293 33.4895 139.5 34.54 139.5H106V123.5H34.54Z" fill="#E6E6E6"/>
    <path d="M106.5 327H42.46C38.0855 326.995 33.8915 325.255 30.7982 322.162C27.7049 319.069 25.965 314.875 25.96 310.5V226.5C25.965 222.125 27.705 217.931 30.7982 214.838C33.8915 211.745 38.0855 210.005 42.46 210H106.5V327Z" fill="#E6E6E6"/>
    <path d="M209.76 490.5H122.5C117.993 490.495 113.672 488.702 110.485 485.515C107.298 482.328 105.505 478.007 105.5 473.5V124.5C105.505 119.993 107.298 115.672 110.485 112.485C113.672 109.298 117.993 107.505 122.5 107.5H359.181L402.5 146.178V212.5H400.5V147.074L358.418 109.5H122.5C118.523 109.504 114.71 111.086 111.898 113.898C109.086 116.71 107.504 120.523 107.5 124.5V473.5C107.504 477.477 109.086 481.29 111.898 484.102C114.71 486.914 118.523 488.496 122.5 488.5H209.76V490.5Z" fill="#CCCCCC"/>
    <path d="M401.5 147.5H374.5C369.993 147.495 365.672 145.702 362.485 142.515C359.298 139.328 357.505 135.007 357.5 130.5V108.5C357.5 108.235 357.605 107.98 357.793 107.793C357.98 107.605 358.235 107.5 358.5 107.5C358.765 107.5 359.02 107.605 359.207 107.793C359.395 107.98 359.5 108.235 359.5 108.5V130.5C359.504 134.477 361.086 138.29 363.898 141.102C366.71 143.914 370.523 145.495 374.5 145.5H401.5C401.765 145.5 402.02 145.605 402.207 145.793C402.395 145.98 402.5 146.235 402.5 146.5C402.5 146.765 402.395 147.02 402.207 147.207C402.02 147.395 401.765 147.5 401.5 147.5Z" fill="#CCCCCC"/>
    <path d="M368.04 198H140.04C137.918 198 135.883 198.843 134.383 200.343C132.883 201.843 132.04 203.878 132.04 206C132.04 208.122 132.883 210.157 134.383 211.657C135.883 213.157 137.918 214 140.04 214H368.04C370.162 214 372.197 213.157 373.697 211.657C375.197 210.157 376.04 208.122 376.04 206C376.04 203.878 375.197 201.843 373.697 200.343C372.197 198.843 370.162 198 368.04 198Z" fill="#E6E6E6"/>
    <path d="M253.04 164H140.04C137.918 164 135.883 164.843 134.383 166.343C132.883 167.843 132.04 169.878 132.04 172C132.04 174.122 132.883 176.157 134.383 177.657C135.883 179.157 137.918 180 140.04 180H253.04C255.162 180 257.197 179.157 258.697 177.657C260.197 176.157 261.04 174.122 261.04 172C261.04 169.878 260.197 167.843 258.697 166.343C257.197 164.843 255.162 164 253.04 164Z" fill="#E6E6E6"/>
    <path d="M368.04 198H140.04C137.918 198 135.883 198.843 134.383 200.343C132.883 201.843 132.04 203.878 132.04 206C132.04 208.122 132.883 210.157 134.383 211.657C135.883 213.157 137.918 214 140.04 214H368.04C370.162 214 372.197 213.157 373.697 211.657C375.197 210.157 376.04 208.122 376.04 206C376.04 203.878 375.197 201.843 373.697 200.343C372.197 198.843 370.162 198 368.04 198Z" fill="#E6E6E6"/>
    <path d="M253.04 164H140.04C137.918 164 135.883 164.843 134.383 166.343C132.883 167.843 132.04 169.878 132.04 172C132.04 174.122 132.883 176.157 134.383 177.657C135.883 179.157 137.918 180 140.04 180H253.04C255.162 180 257.197 179.157 258.697 177.657C260.197 176.157 261.04 174.122 261.04 172C261.04 169.878 260.197 167.843 258.697 166.343C257.197 164.843 255.162 164 253.04 164Z" fill="#E6E6E6"/>
    <path d="M140.04 265C137.918 265 135.883 265.843 134.383 267.343C132.883 268.843 132.04 270.878 132.04 273C132.04 275.122 132.883 277.157 134.383 278.657C135.883 280.157 137.918 281 140.04 281H211.5V265H140.04Z" fill="#E6E6E6"/>
    <path d="M140.04 231C137.918 231 135.883 231.843 134.383 233.343C132.883 234.843 132.04 236.878 132.04 239C132.04 241.122 132.883 243.157 134.383 244.657C135.883 246.157 137.918 247 140.04 247H211.5V231H140.04Z" fill="#E6E6E6"/>
    <path d="M140.04 265C137.918 265 135.883 265.843 134.383 267.343C132.883 268.843 132.04 270.878 132.04 273C132.04 275.122 132.883 277.157 134.383 278.657C135.883 280.157 137.918 281 140.04 281H211.5V265H140.04Z" fill="#E6E6E6"/>
    <path d="M140.04 231C137.918 231 135.883 231.843 134.383 233.343C132.883 234.843 132.04 236.878 132.04 239C132.04 241.122 132.883 243.157 134.383 244.657C135.883 246.157 137.918 247 140.04 247H211.5V231H140.04Z" fill="#E6E6E6"/>
    <path d="M212 434.5H147.96C143.585 434.495 139.391 432.755 136.298 429.662C133.205 426.569 131.465 422.375 131.46 418V334C131.465 329.625 133.205 325.431 136.298 322.338C139.391 319.245 143.585 317.505 147.96 317.5H212V434.5Z" fill="#E6E6E6"/>
    <path d="M217.64 216C215.155 217.517 213.101 219.647 211.675 222.186C210.25 224.725 209.501 227.588 209.5 230.5V233H211.5V230.5C211.504 227.193 212.599 223.98 214.615 221.358C216.631 218.737 219.455 216.853 222.65 216H217.64ZM463.18 213.5H226.5C221.993 213.505 217.672 215.298 214.485 218.485C211.298 221.672 209.505 225.993 209.5 230.5V579.5C209.506 584.007 211.3 588.327 214.486 591.514C217.673 594.701 221.993 596.494 226.5 596.5H489.5C494.007 596.494 498.327 594.701 501.514 591.514C504.701 588.327 506.494 584.007 506.5 579.5V252.18L463.18 213.5ZM504.5 579.5C504.495 583.477 502.913 587.289 500.101 590.101C497.289 592.913 493.477 594.495 489.5 594.5H226.5C222.523 594.495 218.711 592.913 215.899 590.101C213.087 587.289 211.505 583.477 211.5 579.5L211.5 230.5C211.504 227.193 212.599 223.98 214.615 221.358C216.631 218.737 219.455 216.853 222.65 216C223.906 215.665 225.2 215.497 226.5 215.5H462.42L504.5 253.07V579.5ZM217.64 216C215.155 217.517 213.101 219.647 211.675 222.186C210.25 224.725 209.501 227.588 209.5 230.5V233H211.5V230.5C211.504 227.193 212.599 223.98 214.615 221.358C216.631 218.737 219.455 216.853 222.65 216H217.64Z" fill="#3F3D56"/>
    <path d="M505.5 253.5H478.5C473.993 253.495 469.672 251.702 466.485 248.515C463.298 245.328 461.505 241.007 461.5 236.5V214.5C461.5 214.235 461.605 213.98 461.793 213.793C461.98 213.605 462.235 213.5 462.5 213.5C462.765 213.5 463.02 213.605 463.207 213.793C463.395 213.98 463.5 214.235 463.5 214.5V236.5C463.504 240.477 465.086 244.29 467.898 247.102C470.71 249.914 474.523 251.496 478.5 251.5H505.5C505.765 251.5 506.02 251.605 506.207 251.793C506.395 251.98 506.5 252.235 506.5 252.5C506.5 252.765 506.395 253.02 506.207 253.207C506.02 253.395 505.765 253.5 505.5 253.5Z" fill="#3F3D56"/>
    <path d="M472.041 320H244.041C241.92 320 239.885 319.157 238.385 317.657C236.884 316.157 236.041 314.122 236.041 312C236.041 309.878 236.884 307.843 238.385 306.343C239.885 304.843 241.92 304 244.041 304H472.041C474.163 304 476.198 304.843 477.698 306.343C479.199 307.843 480.041 309.878 480.041 312C480.041 314.122 479.199 316.157 477.698 317.657C476.198 319.157 474.163 320 472.041 320Z" fill="#CCCCCC"/>
    <path d="M357.041 286H244.041C241.92 286 239.885 285.157 238.385 283.657C236.884 282.157 236.041 280.122 236.041 278C236.041 275.878 236.884 273.843 238.385 272.343C239.885 270.843 241.92 270 244.041 270H357.041C359.163 270 361.198 270.843 362.698 272.343C364.199 273.843 365.041 275.878 365.041 278C365.041 280.122 364.199 282.157 362.698 283.657C361.198 285.157 359.163 286 357.041 286Z" fill="#CCCCCC"/>
    <path d="M472.041 387H244.041C241.92 387 239.885 386.157 238.385 384.657C236.884 383.157 236.041 381.122 236.041 379C236.041 376.878 236.884 374.843 238.385 373.343C239.885 371.843 241.92 371 244.041 371H472.041C474.163 371 476.198 371.843 477.698 373.343C479.199 374.843 480.041 376.878 480.041 379C480.041 381.122 479.199 383.157 477.698 384.657C476.198 386.157 474.163 387 472.041 387Z" fill="#CCCCCC"/>
    <path d="M357.041 353H244.041C241.92 353 239.885 352.157 238.385 350.657C236.884 349.157 236.041 347.122 236.041 345C236.041 342.878 236.884 340.843 238.385 339.343C239.885 337.843 241.92 337 244.041 337H357.041C359.163 337 361.198 337.843 362.698 339.343C364.199 340.843 365.041 342.878 365.041 345C365.041 347.122 364.199 349.157 362.698 350.657C361.198 352.157 359.163 353 357.041 353Z" fill="#CCCCCC"/>
    <path d="M463.959 540.5H251.959C247.584 540.495 243.39 538.755 240.297 535.662C237.204 532.569 235.464 528.375 235.459 524V440C235.464 435.625 237.204 431.431 240.297 428.338C243.39 425.245 247.584 423.505 251.959 423.5H463.959C468.333 423.505 472.527 425.245 475.621 428.338C478.714 431.431 480.454 435.625 480.459 440V524C480.454 528.375 478.714 532.569 475.621 535.662C472.527 538.755 468.333 540.495 463.959 540.5Z" fill="#6595D6"/>
    </svg>
    