import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'rm-more-documents',
    templateUrl: './more-documents.component.html',
    styleUrls: ['./more-documents.component.scss']
})
export class MoreDocumentsComponent implements OnInit {
    constructor() { }

    ngOnInit(): void { }
}
