import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-user-details-svg',
    templateUrl: './user-details-svg.component.html',
    styleUrls: ['./user-details-svg.component.scss']
})
export class UserDetailsSvgComponent implements OnInit {
    constructor() { }

    ngOnInit(): void { }
}
